<script context="module" lang="ts">
  import { preloadIcon } from "@brave/leo/src/components/icon/icon.svelte";
  import { writable } from "svelte/store";

  export const orders = writable<Order[]>([]);

  preloadIcon("check-normal");
</script>

<script lang="ts">
  import { browser } from "$app/environment";
  import { beforeNavigate } from "$app/navigation";
  import { base } from "$app/paths";
  import Footer from "$components/Footer.svelte";
  import PageLayout from "$components/PageLayout.svelte";
  import { globalIntents, isIntent } from "$lib/intents";
  import { getSdkPromise, refreshOrders, type Order } from "$lib/payments-service";
  import { getSubscriber, logout } from "$lib/subscription-service";
  import { loadUser, propagateSafeParams, unloadUser } from "$lib/utils";
  import Button from "@brave/leo/src/components/button/button.svelte";
  import Icon from "@brave/leo/src/components/icon/icon.svelte";
  import Navigation from "@brave/leo/src/components/navigation/navigation.svelte";
  import NavigationActions from "@brave/leo/src/components/navigation/navigationActions.svelte";
  import NavigationHeader from "@brave/leo/src/components/navigation/navigationHeader.svelte";
  import NavigationItem from "@brave/leo/src/components/navigation/navigationItem.svelte";
  import NavigationMenu from "@brave/leo/src/components/navigation/navigationMenu.svelte";
  import { onDestroy, onMount } from "svelte";
  import { isFetching } from "../+layout.svelte";
  import type { LayoutData } from "./$types";

  export let data: LayoutData;
  $: ({ intent, productKey, user, uxMode } = data);
  $: loggedIn = !!user?.subscriber_id;
  $: hideNav = uxMode === "mobile";

  let verifyLoginInterval: NodeJS.Timeout;

  const handleLogout = async () => {
    try {
      await logout();
    } catch (_) {}

    // Clear user from sessionStorage
    unloadUser();

    // Determine where to redirect after logout
    let redirectUrl = base || "/";
    if (isIntent(intent, globalIntents)) {
      redirectUrl = `${base}/?intent=${intent}&product=${productKey}`;
    }

    // Clear verification interval
    clearInterval(verifyLoginInterval);

    // Intentionally not using SvelteKit's `goto` in order to force a full refresh and clear console/network logs
    location.href = propagateSafeParams(location.href, redirectUrl).toString();
  };

  const verifyLogin = () => {
    getSubscriber()
      .then((user) => {
        loadUser(user);
      })
      .catch(() => {
        handleLogout();
      });
  };

  beforeNavigate(({ to, from }) => {
    const destWithParams = propagateSafeParams(from?.url, to?.url);
    if (to?.url && to.url?.search !== destWithParams.search) {
      to.url.search = destWithParams.search;
    }
  });

  onMount(async () => {
    document.body.classList.add("app");

    $isFetching = true;
    if (user) {
      // Poll for logged in status
      verifyLoginInterval = setInterval(verifyLogin, 60_000);

      // Update stores
      $orders = user.subscriptions ? await refreshOrders() : [];
      $isFetching = $orders.length <= 0;

      // Start loading SKUs SDK for future use
      getSdkPromise();
    }
  });

  onDestroy(() => {
    browser && document.body.classList.remove("app");
    clearInterval(verifyLoginInterval);
  });

  let toggleMobileMenu: () => {};
</script>

<!-- TODO: "$orders" isn't the right variable name since it always contains baseline products -->
{#if $orders.length > 0}
  <PageLayout bind:toggleMobileMenu {hideNav}>
    <div
      class="grid h-[--mobile-header-height] grid-cols-3 items-center bg-container-background p-xl shadow-03 [--leo-button-padding:0]"
      slot="mobile-header"
    >
      {#if !hideNav}
        <Button onClick={toggleMobileMenu} size="medium" kind="plain-faint" fab>
          <Icon name="hamburger-menu" />
        </Button>
      {/if}
      <div class="col-start-2 flex gap-m justify-self-center">
        <img class="h-[28px]" src="{base}/images/brave-logo.svg" alt="Brave Premium" />
      </div>
    </div>

    <div class="h-full bg-container-background" slot="navigation">
      <Navigation>
        <NavigationHeader slot="header">
          <img class="h-[28px]" src="{base}/images/brave-logo.svg" alt="Brave Premium" />
          <div class="ml-auto lg:hidden">
            <Button onClick={toggleMobileMenu} size="medium" kind="plain-faint" fab>
              <Icon name="close" />
            </Button>
          </div>
        </NavigationHeader>

        {#if !hideNav}
          <NavigationMenu>
            <NavigationItem icon="user" href="{base}/account/">Account</NavigationItem>
            <NavigationItem icon="crown" href="{base}/plans/">Premium</NavigationItem>
          </NavigationMenu>
        {/if}

        <NavigationActions slot="actions">
          {#if !hideNav}
            <div class="border-t border-divider-subtle pt-m">
              <NavigationItem outsideList={true} href="{base}/support/" icon="help-outline">Support</NavigationItem>
              <NavigationItem outsideList={true} onClick={handleLogout} icon="outside">
                {loggedIn ? "Log out" : "Log in"}
              </NavigationItem>
            </div>
          {/if}
        </NavigationActions>
      </Navigation>
    </div>

    <main class="z-10 mx-auto flex max-w-[--content-max-width] flex-col gap-2xl">
      <slot />
    </main>

    <Footer slot="footer" />
  </PageLayout>
{/if}
