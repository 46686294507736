import { goto } from "$app/navigation";
import ErrorAlert from "$components/ErrorAlert.svelte";
import { showAlert as _showAlert, type AlertOptions } from "@brave/leo/src/components/alert/alertCenter.svelte";
import { handlePurchase, type Order } from "./payments-service";
import { getRecoveryURL, isBrave } from "./utils";
import { base } from "$app/paths";
import type { Product } from "./products";

const ALERT_DURATION = 6000;

export const TOO_MANY_RECOVERIES = ({ displayName }: Product) =>
  showAlert(
    {
      content: `It looks like you’re having trouble authenticating ${displayName} on a new device. To protect the security of your account, we’ve temporarily restricted access. For now, please try accessing ${displayName} from another device that’s already authenticated. You can also try authenticating this new device again in twenty-four hours, or contact Brave support.`,
      type: "warning",
    },
    ALERT_DURATION
  );

// TODO: improve error messaging to be more granular
export const UNSUPPORTED_BROWSER = (message?: string, name?: string, showDownload: boolean = true) => {
  showAlert({
    content: message || `In order to use ${name}, you'll have to use Brave Browser. Need to install Brave Browser?`,
    type: "warning",
    actions:
      showDownload && !isBrave()
        ? [
            {
              text: "Download Brave",
              action: () => window.open("https://brave.com/download/", "_blank", "noopener=true"),
            },
          ]
        : undefined,
  });
};

export const ORDER_UNPAID = (order: Order) =>
  showAlert(
    {
      content: `We couldn't recover your credentials for ${order.product.displayName} since the subscription is not paid.`,
      type: "error",
      actions: [
        {
          text: "Complete purchase",
          action: () => handlePurchase(order),
        },
      ],
    },
    ALERT_DURATION
  );

export const NOT_PURCHASED = (order: Order) =>
  showAlert(
    {
      content: `We couldn't find a subscription associated with your email address.`,
      type: "error",
      actions: order.product.browserSupport.isSupported
        ? [
            {
              text: "Browse plans",
              action: async (a) => {
                await goto(`${base}/plans/`);
                a.dismiss();
              },
            },
          ]
        : undefined,
    },
    ALERT_DURATION
  );

export const SUCCESS = (order: Order) => {
  const { product, productDomain, orderId } = order;

  let actions: Array<any>;
  let content: string;
  if (product.usesNativeSDK && !order.credentialsPresent) {
    content = `Your subscription is confirmed. Final step, click the 'Refresh' button to load your credentials and begin using ${product.displayName}.`;
    actions = [
      {
        text: "Refresh",
        action: async (a) => {
          await goto(`${base}/account/?intent=recover&product=${product.name}`);
          a.dismiss();
        },
      },
    ];
  } else if (product.name === "vpn") {
    content = `Your Brave VPN credentials are now loaded on this device. To start using Brave VPN, click the "VPN" button in the Brave browser toolbar.`;
  } else if (product.name === "leo") {
    content = `Your Brave Leo credentials are now loaded on this device. To start using Brave Leo, type your query in the address bar and select the "Ask Leo" option. You can also find Leo in the Sidebar.`;
  } else {
    content = `We're verifying your access to ${product.displayName}. it should just take a moment. If you're not automatically redirected in 3 seconds, click "Return to ${product.displayName}."`;
    actions = [
      {
        text: `Return to ${product.displayName}`,
        action: () => (location.href = getRecoveryURL(productDomain, orderId)),
      },
    ];
  }

  showAlert(
    {
      content,
      type: "success",
      actions,
    },
    ALERT_DURATION
  );
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const showAlert = (
  options: PartialBy<AlertOptions, "actions" | "mode">,
  duration: number = null,
  dismissible: boolean = true
) => {
  let actions = options.actions ?? [];
  _showAlert({ mode: "simple", ...options, actions }, duration, dismissible);
};

export const showError = (
  options: Omit<PartialBy<AlertOptions, "actions" | "content">, "type" | "component" | "mode"> & { error?: any },
  duration = 0
) => {
  showAlert(
    { content: options.error?.message, ...options, type: "error", component: ErrorAlert, mode: "simple" },
    duration
  );
  options.error && console.error(options.error);
};
