import { base } from "$app/paths";

export const intents = ["checkout", "recover", "connect-receipt", "link-order", "provision"] as const;

export type Intent = (typeof intents)[number];

export const globalIntents: Intent[] = ["checkout", "connect-receipt", "link-order", "recover"];

export const isIntent = <T extends readonly Intent[]>(
  intent: string | undefined | T[number],
  intentSet: T = intents as any
) => intentSet.includes(intent as T[number]);

const intentRedirectsMap: { [P in Intent]?: Function } = {
  "recover": ({ intent, productId }) => `${base}/account/?intent=${intent}&product_id=${productId}`,
  "connect-receipt": ({ productId }) => `${base}/receipt-link/?product_id=${productId}`,
  "link-order": ({ productId }) => `${base}/order-link/?product_id=${productId}`,
};

export const redirectIntents = Object.keys(intentRedirectsMap) as Intent[];

export const resolveIntentRedirect = (intent: Intent, productId: string) => {
  return intentRedirectsMap[intent]?.({ intent, productId });
};
